@import url("https://fonts.googleapis.com/css2?family=Fredoka&display=swap");

$color1: rgb(255, 159, 86);
$black1: rgb(255, 255, 255);
$black2: rgb(169, 169, 169);
$black3: hwb(0 32% 34%);
$white: rgb(247, 208, 173);
$grey: rgb(95, 95, 95);
$shadow: 0 3px 20px rgba(0, 0, 0, 0.356);
$noir1: rgb(0, 0, 0);

@mixin verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fredoka", sans-serif;
  font-size: 1.05rem;
}

body {
  background: $black2;
  color: $noir1;
}

button,
input[type="submit"] {
  background: none;
  border: none;
  cursor: pointer;
  background: $color1;
  transition: 0.2s;

  &:hover {
    filter: brightness(80%);
    transform: translateX(2px);
  }
}

input,
textarea {
  border-radius: 7px;
  font-size: 0.8rem;
  border: none;
  resize: none;
  margin: 5px;
  padding: 8px;
  background: $white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

