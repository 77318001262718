.app-header {
  background: $black1;
  width: 90%;
  min-width: 300px;
  margin: 40px auto 30px;
  text-align: center;
  position: relative;
  padding: 20px 20px;
  border-radius: 0px 10px 10px 10px;
  min-height: 150px;
  box-shadow: $shadow;

  .connect-modal {

    .header-btn {
      position: absolute;
      left: 0;
      top: -29px;

      button {
        color: black;
        background: $black1;
        padding: 4px 14px;
        border-radius: 10px 10px 0 0;
        transition: 0.2s;

        &:hover {
          filter: brightness(135%);
        }
      }
    }
    .signup-container,
    .login-container {
      min-height: 20px;
      @include verticalCenter;

      h3 {
        margin-bottom: 8px;
      }

      form {
        display: grid;

        input {
          text-align: center;
          padding: 8px 10px;
          transition: 0.2s;
        }
      }
    }
  }
  .user-infos {
    display: flex;
    align-items: center;
    position: relative;
    user-select: none;

    span {
      background: $color1;
      height: 30px;
      width: 1Z0px;
      @include verticalCenter;
      border-radius: 150px;
      text-transform: uppercase;
      box-shadow: $shadow;
      transform: translateY(-10px);
    }
    h4 {
      text-transform: capitalize;
      font-size: 2.5rem;
      transform: translate(10px, -10px);
    }
    button {
      position: absolute;
      right: 6px;
      top: 0;
      background: none;
      color: $color1;
    }
  }

  .monbouton1 {
    button {
      font-size: 10px;
      padding: 10px 10px;
      border-radius: 5px;
      margin: 10px 0px;
      cursor: pointer;

      &:hover {
        filter: brightness(135%);
      }
    }
  }

  .monbouton {
    button {
      background-color: rgb(104, 104, 104);
      color: white;
      font-size: 20px;
      padding: 10px 10px;
      border-radius: 5px;
      margin: 10px 0px;
      cursor: pointer;

      &:hover {
        filter: brightness(135%);
      }
    }
  }

  .new-post-modal {
    form {
      display: grid;

      textarea {
        height: 14px;
        font-size: 1.3rem;
        border-radius: 10px 10px 0 10px;
      }
      input {
        width: 100px;
        margin-left: auto;
        margin-top: 0px;
        border-radius: 10px 0 10px 10px;
      }
    }
  }
}
